.car-range-container {
    width: 100%;
    padding: 40px 0;
    background-color: #f8f9fa;
  }
  
  .car-range-header {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 30px;
    text-decoration: none; /* Removes underline */
  }
  
  .car-range-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .sidebar {
    width: 250px;
    margin-right: 20px;
  }
  
  .sidebar-button {
    display: block;
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: #fff;
    background-color: #0056b3;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .sidebar-button:hover {
    background-color: #004494;
  }
  
  .carousel-wrapper {
    flex-grow: 1;
  }
  
  .carousel-caption {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px;
    border-radius: 5px;
  }
  
  .carousel-caption h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
  }
  