/* FeaturedCars.css */
h1 {
    text-align: center;
}

#main {
    height: 540px;
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

#access {
    height: 420px;
    width: 400px;
    background-image: url("./image/Marutiwheel.avif");
    position: relative;
    display: flex;
    gap: 10px;
}

#access div {
    position: absolute;
    z-index: 2;
    color: white;
    background-color: black;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    gap: 20px;
    font-weight: bold;
    font-size: 20px;
    align-items: center;
    top: 100%;
}

#access2 {
    height: 420px;
    width: 800px;
    display: grid;
    gap: 10px;
    grid-template-rows: auto auto;
}

#access21,
#access22 {
    height: 226px;
    width: 100%;
    display: flex;
    gap: 10px;
}

#access211,
#access212,
#access213 {
    height: 225px;
    width: 280px;
    background-size: 100% 100%;
    position: relative;
    background-repeat: no-repeat;
}

#access211 {
    background-image: url("./image/Foglights.png");
}

#access212 {
    background-image: url("./image/seats.avif");
}

#access213 {
    background-image: url("./image/CarpetMat.avif");
}

#access211 div,
#access212 div,
#access213 div {
    position: absolute;
    z-index: 2;
    color: white;
    background-color: black;
    width: 100%;
    height: 40px;
    display: flex;
    gap: 20px;
    font-weight: bold;
    font-size: 16px;
    align-items: center;
    top: 85%;
    cursor: pointer;
    font-family: cursive;
}

#access221 {
    height: 253px;
    width: 480px;
    background-image: url("./image/door.webp");
    background-size: 100% 100%;
    position: relative;
    background-repeat: no-repeat;
}

#access222 {
    height: 253px;
    width: 380px;
    background-image: url("./image/interior.avif");
    background-size: 100% 100%;
    position: relative;
    background-repeat: no-repeat;
}

#access221 div,
#access222 div {
    position: absolute;
    z-index: 2;
    color: white;
    background-color: black;
    width: 100%;
    height: 40px;
    display: flex;
    gap: 20px;
    font-weight: bold;
    font-size: 16px;
    align-items: center;
    top: 85%;
    cursor: pointer;
    font-family: cursive;
}
