.footer {
    background-color: #f2f2f2;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-section {
    flex: 1;
    padding: 10px;
  }
  
  .footer-section h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 5px;
    color: #555;
  }
  
  .footer-section p {
    color: #555;
    margin-bottom: 5px;
  }
  
  .stars {
    color: #f39c12; /* gold color */
    font-size: 20px;
  }
  
  .review-btn, .facebook-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .facebook-btn {
    background-color: #3b5998;
  }
  
  .review-btn:hover, .facebook-btn:hover {
    opacity: 0.8;
  }
  
  .social-media {
    margin-top: 10px;
  }
  
  .social-media p {
    margin: 0;
    color: #555;
  }
  